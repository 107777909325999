import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquest gènere posseeix sempre els capells en forma de didal, molt delicats i poc carnosos. Aquesta espècie té el capell de 2 a 4 cm de diàmetre de color torrat, gris marronenc per passar a gris metàl·lic amb l’edat. Pot presentar petites taques o estar aureolat en temps sec. Les làmines són fines, molt espaiades, adnades i de color primer gris verdós, després cafè amb llet i finalment negroses amb l’aresta més blanca. El peu és llarg i ondulat, cilíndric, una mica pelut i de color semblant al capell. Les espores són negres en massa, el·líptiques amb un porus ben marcat, de 15-17 x 9-11 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      